import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element);
Vue.config.productionTip = false;
import { asyncPost,asyncGet,asyncDelete,asyncPUT} from '@/api/async'
Vue.prototype.asyncPost=asyncPost
// Vue.prototype.asyncGet=asyncGet
// Vue.prototype.asyncDelete=asyncDelete
// Vue.prototype.asyncPUT=asyncPUT
//  全局公共方法
import Global from '@/utils/public'
Vue.prototype.publicFun = Global.publicFun

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
