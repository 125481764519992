import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({ 
	state: {
		userInfo: {},
		articleThemes:[],		//主题
		article:{
			content:'',			//内容
			title: '',			//标题
		},
		attribute:{
			coverAlign: 0,		//样式
			coverTxt: '',		//摘要
			coverImage: '',		//封面
			labelNames: [],		//标签
			sourceInput: '',	//转自
		}
	},
	mutations: {
		upUserInfo(state, data) {
		    state.userInfo = data
		},
		uparticleThemes(state, data) {
		    state.articleThemes = data
		},
		upArticle(state, data) {
		    state.article = data
		},
		upAttribute(state, data) {
		    state.attribute = data
		},
	},
	actions:{
		setUserInfo(content,data){
			content.commit('upUserInfo',data)
		},
		setarticleThemes(content,data){
			content.commit('uparticleThemes',data)
		},
		setArticle(content,data){
			content.commit('upArticle',data)
		},
		setAttribute(content,data){
			content.commit('upAttribute',data)
		},
	}
}) 
export default store
