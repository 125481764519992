import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',//录入文章登录b
    name: 'centerLogin',
    component: () => import(/* webpackChunkName: "centerLogin" */ '../views/openWriter/centerLogin.vue')
  },
  {
    path: '/openWriter/addArticle',
    name: 'addArticle',
    component: () => import(/* webpackChunkName: "addArticle" */ '../views/openWriter/addArticle.vue'),
  },
  {
    path: '/openWriter/longlog',
    name: 'longlog',
    component: () => import(/* webpackChunkName: "longlog" */ '../views/openWriter/longlog.vue'),
  },
  {
    path: '/openWriter/attribute',
    name: 'attribute',
    component: () => import(/* webpackChunkName: "attribute" */ '../views/openWriter/attribute.vue'),
  },
  {
    path: '/openWriter/themeList',
    name: 'themeList',
    component: () => import(/* webpackChunkName: "themeList" */ '../views/openWriter/themeList.vue')
  },
  {
    path: '/openWriter/longPreview',
    name: 'longPreview',
    component: () => import(/* webpackChunkName: "longPreview" */ '../views/openWriter/longPreview.vue')
  },
  {
    path: '/preview',//分享
    name: 'preview',
    component: () => import(/* webpackChunkName: "preview" */ '../views/openWriter/preview.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
