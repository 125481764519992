const publicFun = {
  //获取当前时间 ；时间格式化
  formDat(date = '', type = true){
  	date = date ? new Date(date) : new Date()
  	const year = date.getFullYear()
  	const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  	const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  	if (type) {
  		return `${year}/${month}/${day} ${h}:${m}:${s}`
  	} else {
  		return `${year}/${month}/${day}`
  	}
  }
}

export default {
  publicFun
}
